import React from 'react'
import { graphql } from 'gatsby'
import CareerHeader from '../components/header/careerHeader'
import CommonFooter from '../components/footer/commonFooter'
import Section14 from '../components/sections/section14'
import Seo from '../components/seo/seo'
import { useTranslation } from 'react-i18next'

const CareerTemplate = ({ data }) => {

  const { t } = useTranslation();
  const { markdownRemark } = data // data.markdownRemark holds the template data
  const { frontmatter, html } = markdownRemark

  const {
      name,
      headline,
      heraHeroImage1,
      introTitle,
      introQuote,
    } = frontmatter

    return (
      <>
        <Seo
          title={name}
          description={introTitle}
          seoImage={heraHeroImage1.childImageSharp.resize.src}
        />
        <CareerHeader
          headerTitle={name}
          headerSubtitle={headline}
          fluid={heraHeroImage1.childImageSharp.fluid}
        />
        <Section14 title={introTitle} blockquote={introQuote} html={html} />
        <div className="maingrad py-24 text-center">
          <div className="content is-large">
            <a
              href="mailto:hello@ctaelectronics.com"
              className="title is-1 is-white"
            >
              {t('careerTemplate.cta')}
            </a>
          </div>
        </div>
        <CommonFooter />
      </>
    )
  }

export default CareerTemplate

export const CareerQuery = graphql`
  query Career($localizedPath: String!) {
    markdownRemark(fields: { slug: { eq: $localizedPath } }) {
      html
      frontmatter {
        path
        name
        headline
        introTitle
        introQuote
        heraHeroImage1 {
          childImageSharp {
            fluid(maxWidth: 1900) {
              ...GatsbyImageSharpFluid
            }
            resize(width: 900, quality: 90) {
              src
            }
          }
        }
      }
    }
  }
`
