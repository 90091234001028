//No title
//primary blockquote on the left and dangerouslySetInnerHTML on the right

import React from 'react'
import SlideInTop from '../utility/slideInTopAnimation'

const Section14 = props => (
  <div className="section">
      <div className="md:flex md:space-x-8 space-y-4 md:space-y-0 md:items-center">
        {props.blockquote && (
            <div className="md:w-1/3 bg-gray-50 rounded-xl p-8 drop-shadow-md">
              <SlideInTop>
                  <h3 className="text-2xl font-semibold">{props.title}</h3>
                  <p className='mt-8 py-8 border-y-2 border-dotted'>{props.blockquote}</p>
              </SlideInTop>
            </div>
        )}
        <div className="md:w-2/3 border bg-gray-50 border-gray-50 rounded-xl p-8 drop-shadow-md">
          <SlideInTop>
              <div className="prose" dangerouslySetInnerHTML={{ __html: props.html }} />
          </SlideInTop>
        </div>
      </div>
  </div>
)

export default Section14
